import { foreachElement } from './utils';

export function initializeIntroSlider() {
  foreachElement('.ce_rsce_intro-image .swiper-container', (element) => {
    const swiper = new Swiper(element, {
      direction: 'horizontal',
      slidesPerView: '1',
      spaceBetween: 0,
      loop: true,
      speed: 700,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      }
    });
  });
};
